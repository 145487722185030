const messages = {
  device: {
    not_registered: "This device is not registered",
    registered: "This device is regisetered"
  },
  field: {
    please_select: "Please select ...",
    autocomplete_hint: "Mininum 2 characters to trigger autocomplete",
    plus_vat: " (+VAT)",
    without_vat: " (no VAT)",
    postcode: "Postcode / Zip Code"
  },
  dateTimeFormat: "D MMMM YYYY, h.mma",
  dateFormat: "D MMMM YYYY",
  timeFormat: "h.mma",
  error: {
    404: "The requested record(s) could not be found on the server",
    422: "The submitted data could not be processed due to validation errors",
    500: "An internal error has occurred on the server, your request could not be processed"
  },
  general: {
    tbc: "TBC"
  },
  enquiry: {
    appointment: {
      dateAndTime: "Notarisation Date and Time", 
      qualifiers: "Appointment qualifiers", 
      toFollow: "Appointment to follow"
    },
    transaction_descriptions: "Summary of client activity/transaction",
    documents_signed_for:
      "Confirm signing capacity for documents",
    requires_legalisation: "Some of these documents require legalisation",
    informed_legalisation: "Have you been told about it?",
    desires_legalisation: "Would you like to have these documents legalised?",
    manage_legalisation:
      "Would you like us to manage the legalisation element for you?",
    fees: {
      //- #6779
      fco:
        "FCDO apostille / certification steps and associated fees (per document)"
    },
    notary_fee: "Notarial professional fee",
    special_instructions: "Special forwarding instructions",
    address: "Address",
    courier_info_disclaimer:
      "Please make sure you have name, full address and contact number for the forwarding address available at the appointment.",
    enquirer_type: "Initial enquirer type"
  }
};

export default messages;
