import { lcfirst, ucfirst } from "locutus/php/strings";
import { empty } from "locutus/php/var";
import _ from "lodash";
import { uid } from "quasar";
import { difference } from "set-manipulator";
import * as types from "src/store/constants";
import { strip, contactAddress, contactName, personRequiredFields, getAssociatedIdFromRelatedOrRelationship } from "src/utils";

export { getField } from "src/utils/vuex-map-fields";

/**
 * Because this getter is used often, direct logging becomes overly verbose
 * This deferred log function has to be enabled by passing a value for from in.
 * So pick the function you want to monitor,
 * find the file where it's called from, and pass the from value in when calling it. 
 * This is best done in the unit tests.
 * @param {string} from 
 * @param {string} label 
 * @param {any} message 
 */
function log(from, label, message) {
  if (from && typeof label !== "undefined") {
    console.log("from " + from + ":", label, message);
  }
}

/********************************************
 * PRIVATE FUNCTIONS
 *******************************************/
const _collectContacts = (state, getters) => {
  const contacts = [];

  //- get Representatives
  const representatives = getters.getField(
    `${getters.formDataPath}.related.Representatives`
  );
  //- get Clients
  const clients = getters.getField(`${getters.formDataPath}.related.Clients`);
  //- get Notary
  const notary = getters.getField(`${getters.formDataPath}.related.Notary`);
  //- get Organisations
  const organisations = getters.getField(
    `${getters.formDataPath}.related.Organisations`
  );
  //- get Invoicees
  const invoicees = getters.getField(
    `${getters.formDataPath}.related.Invoicees`
  );

  //- add to contacts array
  if(Array.isArray(clients)) {
    clients.forEach(client => {
      contacts.push(client);
    });
  } else if(clients.id) {
    contacts.push(clients);
  }

  if(Array.isArray(representatives)) {
    representatives.forEach(representative => {
      contacts.push(representative);
    });
  } else if(representatives.id) {
    contacts.push(representatives);
  }

  if(Array.isArray(notary)) {
    notary.forEach(notary => {
      contacts.push(notary);
    });
  } else if(notary?.id) {
    contacts.push(notary);
  }

  if(Array.isArray(organisations)) {
    organisations.forEach(organisation => {
      contacts.push(organisation);
    });
  } else if(organisations.id) {
    contacts.push(organisations);
  }

  if(Array.isArray(invoicees)) {
    invoicees.forEach(invoicee => {
      contacts.push(invoicee);
    });
  } else if(invoicees.id) {
    contacts.push(invoicees);
  }
  return contacts;
};

export const incompleteContacts = (state, getters) => {
  const contacts = _collectContacts(state, getters);
  if (!contacts.length) return [];
  const incompleteContacts = [];
  for(const contact of contacts) {
    //- iterate over the contacts.attributes object's properties and check for keys that match personRequiredFields
    const requiredFields = personRequiredFields;
    let valid = true;
    for(const field of requiredFields) {
      if(!contact.attributes[field]) {
        valid = false;
      }
    }
    if(!valid) {
      incompleteContacts.push(contact);
    }
  }
  return incompleteContacts;
}
/********************************************
 * PUBLIC FUNCTIONS
 *******************************************/

/**
 * @returns organisations assigned to documents
 * @why : Return references to the organisation store records
 * Then if we wish to use these for form storage, clone
 * each locally within the responsible component
 */
export const allComplianceRecordsHaveAuthority = (state, getters) => {
  let valid = true;
  for (let i = 0; i < getters.complianceRecords.length; i++) {
    const compliance = getters.complianceRecords[i];
    if (!getters.complianceRecordHasAuthority(compliance)) {
      valid = false;
    }
  }
  return valid;
};

export const allDocumentsUploaded = (state, getters) => {
  const badges =
    getters.documents?.map((d) => getters.documentHasFile(d)) || [];
  return !badges.includes(false);
};

// used by Enquiry Summary to color expander icons
export const appointmentDetailsCompleted = (state, getters) => {
  const appointmentToFollow = _.get(
    state,
    `${getters.formDataPath}.attributes.appointmentToFollow`,
    false
  );
  if (appointmentToFollow) return false;
  
  // check addresses are complete for each contact
  const allValid = getters.contactAddressDetailsComplete;
  if (!allValid) return false;
  return true;
};

export const associations = (state, getters, rootState, rootGetters) => {
  return _.isEmpty(getters.schema)
    ? []
    : rootGetters["schemas/associations"](getters.schema);
};

/**
 * @why filters out any noise within the entity object and returns a list of expected attributes
 * @returns Array
 */
export const attributeNames = (state, getters, rootState, rootGetters) => {
  if (!getters.isLoaded) return false;
  if (_.isEmpty(getters.schema)) return false;
  if (!empty(getters.isUpdate) && empty(getters.entity)) return false;
  if (_.isEmpty(getters.currentFormData)) return false;
  const options = {
    schema: getters.schema,
    entity: getters.entity
      ? getters.entity
      : rootGetters["schemas/entityDammit"](getters.schema, null), //@why: schemas/dirtyAttributes can't evaluate without an entity
    data: getters.currentFormData,
    from: "submissions getter",
  };
  return rootGetters["schemas/attributeNames"](options);
};

export const authorisations =
  (state, getters, rootState, rootGetters) => (signatory) => {
    return rootGetters["authorisations/related"]({
      parent: signatory,
    });
  };

export const clients = (state, getters, rootState, rootGetters) => {
  const documents = getters.documents;
  if (!documents?.length) return [];
  let clients = new Set([]);
  _.each(documents, (document) => {
    let Clients = document.related?.Clients;
    //- cater for variable types - object or array (i.e. one or many)
    if (Clients != undefined) {
      if (Clients.length) {
        //- array
        Clients.map((client) => {
          clients.add(client);
        });
      } else {
        //- object
        if (Clients.id !== undefined) {
          clients.add(Clients);
        }
      }
    }
  });

  const notarialClients = _.get(
    state,
    `${getters.formDataPath}.related.Clients`,
    []
  );
  if (notarialClients != undefined) {
    if (notarialClients.length) {
      //- array
      notarialClients.map((client) => {
        clients.add(client);
      });
    } else {
      //- object
      if (notarialClients.id !== undefined) {
        clients.add(notarialClients);
      }
    }
  }

  const clientEntities = rootGetters["people/idIn"]({
    ids: [...clients].map((x) => x.id),
  });
  return clientEntities;
};

export const complianceRecordHasAuthority =
  (state, getters) => (compliance) => {
    let auth;

    const compliancePersonId = getAssociatedIdFromRelatedOrRelationship(
      compliance,
      "Person",
      "People"
    );

    /**
     * Check selected authorisation first as submissions should override saved records
     */
    const complianceSubmissions = getters.getField(
      `${getters.formDataPath}.related.Compliance`
    );
    if (complianceSubmissions) {
      const complianceSubmission = complianceSubmissions.find(
        (obj) => obj.id == compliance.id
      );
      if (
        complianceSubmission &&
        complianceSubmission?.related?.Authorisations
      ) {
        const authorisation = complianceSubmission.related.Authorisations;
        if (
          authorisation?.relationships?.person?.data?.id == compliancePersonId
        ) {
          auth = { ...authorisation };
        }
      }
    }

    /**
     * If could not find submission for Compliance, then check saved authorisation record for compliance.
     */
    if (empty(auth)) {
      auth = getters.getAuthorisationRecord(compliance);
    }

    const role = getters.complianceRole(compliancePersonId);

    if (empty(auth)) {
      return false;
    }
    let authClientId;
    switch (getters.who) {
      case types.ENQUIRY_WHO.ind:
        //- if the person id of the selected authorisation matches that of the compliance record (checked above)
        //- and the date is valid then it's valid
        // console.log('type 1', auth.attributes);
        if (!isExpired(auth.attributes.expiryDate)) return true;
        break;

      case types.ENQUIRY_WHO.rep:
        // console.log("type 2");
        if (role == "client") return true;
        if (empty(auth)) return false;
        authClientId = getAssociatedIdFromRelatedOrRelationship(auth, "Client");
        if (authClientId === compliancePersonId) return true;
        if (!isExpired(auth.attributes.expiryDate)) return true;
        break;
      case types.ENQUIRY_WHO.org:
        // console.log("type 3");
        if (empty(auth)) return false;
        if (!isExpired(auth.attributes.expiryDate)) return true;
        break;
    }
    return false;
  };

/**
 * gets compliance records from the submission
 * @returns Array
 */
export const complianceRecords = (state, getters, rootState, rootGetters) => {
  const path = `${getters.formDataPath}.related.Compliance`;
  const compliance = _.get(state, path);
  if (!compliance?.length) return [];
  const records = compliance.map(
    (c) => rootGetters["compliance/byId"]({ id: c.id }) || c
  );
  if (!empty(records)) return records;
  return [];
};

export const complianceRecordsAreReady = (state, getters) => {
  const badges = getters.complianceRecords.map((c) =>
    // getters.complianceValidSignature(c) &&
    getters.complianceRecordHasAuthority(c)
  );
  return !badges.includes(false);
};

export const complianceRole = (state, getters) => (personId) => {
  if (getters.clients.length) {
    if (getters.clients.map((x) => x.id).includes(personId)) return "client";
  }
  if (getters.representatives.length) {
    if (getters.representatives.map((x) => x.id).includes(personId))
      return "representative";
  }
  return undefined;
};

export const complianceSubmissions = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const path = `${getters.formDataPath}.related.Compliance`;
  const compliance = _.get(state, path);
  return compliance;
};

export const complianceSubmissionsAreReady = (state, getters) => {
  const badges = getters.complianceSubmissions.map((c) =>
    // getters.complianceValidSignature(c) &&
    getters.complianceSubmissionHasAuthority(c)
  );
  return !badges.includes(false);
};

export const complianceValidSignature = (state, getters) => (signatory) => {
  return (
    signatory.attributes.signatureSvg != null &&
    signatory.attributes.signatureDate != null
  );
};

// used here by appointmentDetailsComplete
export const contactAddressDetailsComplete = (state, getters) => {
  if (empty(getters.entity)) return false;
  const contacts = getters.getContacts;
  const requiredFieldsForCompletion = [
    "name",
    "surname",
    "email",
    "phone",
    "address1",
    "town",
    "country",
    "postcode",
  ];
  let valid = true;
  for (const contact of contacts) {
    // ingore quotee / invoicee if not required
    if (
      contact.contact_type === "quotee" &&
      !getters.entity.attributes.quoteeRequired
    )
      return valid;
    if (
      contact.contact_type === "invoicee" &&
      !getters.entity.attributes.invoiceeRequired
    )
      return valid;

    // check that the contact.attributes[ each requiredFieldsForCompletion] has a value
    valid = requiredFieldsForCompletion.reduce((summedValidity, curr) => {
      // console.debug('curr', curr)
      // console.debug('contact.attributes?.[curr]', contact.attributes?.[curr])
      // console.debug(contact)
      const valueExistsForThisColumnInThisContact =
        !!contact.attributes?.[curr];
      if (!valueExistsForThisColumnInThisContact) {
        return false;
      }
      if (!summedValidity) return false;
      return true;
    }, valid);
  }
  return valid;
};

export const currentFormData = (state, getters) => {
  return getters.getField(getters.formDataPath);
};

export const dataProtectionIsReady = (state, getters) => {
  const badges = getters.signatories.map((signatory) =>
    // getters.signatoryValidProofOfId(signatory) &&
    // getters.signatoryValidProofOfAddress(signatory) &&
    getters.dataProtectionValidSignature(signatory)
  );
  return !badges.includes(false);
};

export const dataProtectionValidSignature = (state, getters) => (signatory) => {
  return (
    signatory.attributes.gdprSignatureSvg != null ||
    signatory.attributes.gdprSignatureWhere > 1
  );
};

/**
 * @why can be used e.g. to have two forms on the same page, each updating the same entity.. allows dirtyDetection to be compartmental.
 * @returns Array
 */
export const dirtyAttributes = (state, getters, rootState, rootGetters) => {
  if (!getters.isLoaded) return false;
  if (_.isEmpty(getters.schema)) return false;
  if (!empty(getters.isUpdate) && empty(getters.entity)) return false;
  if (_.isEmpty(getters.currentFormData)) return false;
  const options = {
    schema: getters.schema,
    entity: getters.entity
      ? getters.entity
      : rootGetters["schemas/entityDammit"](getters.schema, null), //@why: schemas/dirtyAttributes can't evaluate without an entity
    attributes: getters.currentFormData.attributes,
    from: "submissions getter",
  };
  return rootGetters["schemas/dirtyAttributes"](options);
};

export const disableSearch = (state) => {
  return state.disableSearch;
};

export const documentHasFile =
  (state, getters, rootState, rootGetters) => (document) => {
    const file = rootGetters["files/related"]({
      parent: {
        id: document.id,
        type: document.type,
      },
      relationship: "file",
    });
    return file == null ? false : true;
  };

export const documentOrganisations = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const documents = getters.documents;
  if (!documents?.length) return [];
  let docOrgs = new Set([]);
  _.each(documents, (document) => {
    let organisations = document.related?.Organisations;
    //- cater for variable types - object or array (i.e. one or many)
    if (organisations !== undefined) {
      if (organisations.length) {
        //- array
        organisations.map((org) => {
          if (![...docOrgs].filter((x) => x.id === org.id).length) {
            docOrgs.add(org);
          }
        });
      } else {
        //- object
        if (
          organisations.id !== undefined &&
          ![...docOrgs].filter((x) => x.id === organisations.id).length
        ) {
          docOrgs.add(organisations);
        }
      }
    }
  });
  const orgs = rootGetters["organisations/idIn"]({
    ids: [...docOrgs].map((x) => x.id),
  });
  return orgs;
};

export const documents = (state, getters) => {
  const path = `${getters.formDataPath}.related.Documents`;
  const documents = _.get(state, path);
  return documents;
};

export const enquiryOrganisations = (state, getters) => {
  return state.forms.enquiry.data.related.Organisations;
};


export const entity = (state, getters, rootState, rootGetters) => {
  if (!getters.schema || !getters.id) {
    return undefined;
  }
  const funcName = `${getters.schema}/byId`;
  const entity = rootGetters[funcName]({
    id: getters.id,
  });
  return entity;
};

export const existingNotarialComplianceRecords = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  if (!getters.entity) return false;
  const existingNotarialComplianceRecords = rootGetters["compliance/related"]({
    parent: { id: getters.entity.id, type: getters.entity.type },
  });
  return existingNotarialComplianceRecords;
};

export const expectedDocuments = (state, getters) => {
  if (getters.formDataPath === undefined) return undefined;
  return getters.getField(
    `${getters.formDataPath}.attributes.expectedDocuments`
  );
};

export const countExpectedDocuments = (state, getters) => {
  return getters.expectedDocuments.reduce((prev, row) => {
    prev += parseInt(row.number);
    return prev;
  }, 0);
};
export const countExpectedDocumentsInCountry =
  (state, getters) => (country_code) => {
    return getters.expectedDocuments.reduce((prev, row) => {
      if (row.countryCode === country_code) {
        prev += parseInt(row.number);
      }
      return prev;
    }, 0);
  };

export const FCOstepSelected =
  (state, getters, rootState, rootGetters) => (country_code) => {
    const valid = [
      types.COUNTRY_RULES.apostille,
      types.COUNTRY_RULES.certification,
      types.COUNTRY_RULES.consulate,
      types.COUNTRY_RULES.chamber,
      types.COUNTRY_RULES.varies,
    ];
    let step = getters.getCountryLegalisationStep(country_code);
    if (_.isEmpty(step)) return false;
    if (_.isEmpty(step.selected)) return false;

    /* find rules that aren't in the set of valid rules */
    const invalidSet = difference(step.selected, valid);
    const invalid = !!invalidSet.length;
    const value = !invalid;
    return value;
  };

export const fees = (state, getters) => {
  if (getters.formDataPath === undefined) return undefined;
  return getters.getField(`${getters.formDataPath}.attributes.fees`);
};

export const formDataPath = (state) => {
  return `forms.${state.path}.data`;
};

export const formSettingsPath = (state) => {
  return `forms.${state.path}.settings`;
};

export const getAddressOptions = (state, getters) => {
  const contacts = getters.getContacts;
  // console.debug('contacts', contacts)
  if (!contacts.length) return [];
  const addressOptions = [];
  contacts.forEach((contact) => {
    const address = contactAddress(contact.attributes);
    if (address && address.length) addressOptions.push(address);
  });
  return addressOptions;
};

export const getAncillaryServiceKeyByIdx =
  (state, getters, rootState, rootGetters) => (serviceIdx) => {
    return Object.keys(rootGetters["fees/feeLabels"])[serviceIdx];
  };

export const getAncillaryServiceLabelByIdx =
  (state, getters, rootState, rootGetters) => (serviceIdx) => {
    const feeLables = rootGetters["fees/feeLabels"];
    return feeLables[Object.keys(feeLables)[serviceIdx]];
  };

export const getAncillaryServiceLabelByServiceKey =
  (state, getters, rootState, rootGetters) => (serviceKey) => {
    const feeLabels = rootGetters["fees/feeLabels"];
    return feeLabels[serviceKey];
  };

export const getAuthorisationRecord =
  (state, getters, rootState, rootGetters) => (row) => {
    const auth =
      rootGetters["authorisations/byId"]({
        id: row.relationships?.authorisation?.data?.id,
      }) || null;
    return auth;
  };

/**
 * Gets a collection from a static path. E.g. `enquiry.fees`
 * @param {object} state
 * @param {object} getters
 */
export const getCollection = (state, getters) => (pathToCollection) => {
  state;
  const collection = getters.getField(pathToCollection);
  return collection;
};

/**
 * Get's a row from a collection based on a dynanic key/value within that collection E.g. `enquiry.fees`, 'country_coee', `ZA`
 * @param {object} state
 * @param {object} getters
 */
export const getCollectionRowByField =
  (state, getters) => (pathToCollection, fieldName, fieldValue) => {
    const collection = getCollection(state, getters)(pathToCollection);
    const row = collection.filter((x) => x[fieldName] === fieldValue);
    if (_.isEmpty(row)) {
      return undefined;
    }
    return row[0];
  };

/**
 * Get's a specific value from a dynamically referenced collection row E.g. `enquiry.fees`, `country_code`, `ZA`, `expenses`
 * @param {object} state
 * @param {object} getters
 */
export const getCollectionValueByField =
  (state, getters) => (pathToCollection, fieldName, fieldValue, property) => {
    const row = getCollectionRowByField(state, getters)(
      pathToCollection,
      fieldName,
      fieldValue
    );
    if (_.isEmpty(row)) {
      return undefined;
    }
    return row[property];
  };

export const getContactAddress =
  (state, getters) => (contact) => {
    return contactAddress(contact);
  };

export const getContactNameOptions = (state, getters) => {
  const contacts = getters.getContacts;
  if (!contacts.length) return [];
  const nameOptions = [];
  contacts.forEach((contact) => {
    const name = contactName(contact);
    if (name && name.length) nameOptions.push(name);
  });
  return nameOptions;
};

/**
 * Alias
 * @param {object} state
 */
export const getContacts = (state, getters) => {
  return _collectContacts(state, getters);
};

/**
 * Get's a country row from a country-keyed collection E.g. `enquiry.fees`, `ZA`
 * @param {object} state
 * @param {object} getters
 */
export const getCountryCollectionRow =
  (state, getters) => (pathToCollection, country_code) => {
    const row = getCollectionRowByField(state, getters)(
      pathToCollection,
      "country_code",
      country_code
    );
    return row;
  };

/**
 * Get's a specific value from a country-based collection row E.g. `enquiry.fees`, `ZA`, `expenses`
 * @param {object} state
 * @param {object} getters
 * @param {object} rootState
 * @param {object} rootGetters
 */
export const getCountryCollectionValue =
  (state, getters, rootState, rootGetters) =>
    (pathToCollection, country_code, property) => {
      const row = getCountryCollectionRow(state, getters)(
        pathToCollection,
        country_code
      );
      return row[property];
    };

export const getCountryDocuments = (state, getters) => (countryCode) => {
  const documents = getters.documents;
  if (!documents?.length) return [];
  return documents.filter((x) => x.attributes.countryCode === countryCode);
};

/**
 * Gets the index of a country row, E.g. `enquiry.fees`, `ZA`
 * @param {object} state
 * @param {object} getters
 */
export const getCountryRowIdx =
  (state, getters) => (pathToCollection, country_code) => {
    const collection = getters.getCollection(pathToCollection);
    let idx;
    collection.forEach((x, i) => {
      if (x.country_code === country_code) idx = i;
    });
    return idx;
  };

/**
 *
 * @param {string} country_code
 * @returns
 */
export const getCountryLegalisationStep =
  (state, getters, rootState) => (country_code) => {
    //- alias legalisationSteps
    const steps = _.get(
      rootState.submissions,
      `${getters.formDataPath}.attributes.legalisationSteps`,
      []
    );
    //- filter them for this country
    const countryStep = steps.filter((x) => x.country_code === country_code)[0];
    return countryStep;
  };

export const getDateField = (state, getters) => (val) => {
  return getMnrDate(getters.getField(val), "dateFormat");
};

export const getDateTimeField = (state, getters) => (val) => {
  return getMnrDate(getters.getField(val), "dateTimeFormat");
};

//- #6852
export const getDeliveryLabel =
  (state, getters, rootState, rootGetters) => (countryCode) => {
    let step = getters.getCountryLegalisationStep(countryCode);
    if (_.isEmpty(step)) return false;
    return `Delivery costs (${rootGetters["shared/getOptionLabel"](
      "document_fwd_delivery_type",
      step.document_fwd_delivery_type
    )})`;
  };

export const getEnquiry = (state) => {
  return state.enquiry;
};

/**
 * Summarises enquiry documents based on number and descriptions
 * @param {object} state
 * @param {object} getters
 * @param {object} rootState
 * @param {object} rootGetters
 */
export const getEnquiryDocuments =
  (state, getters, rootState, rootGetters) => (path) => {
    const documentsCollection = getters.getCollection(path);
    let docs = [];
    let number = 0;
    let filteredDocs = [];
    let emptyRow = {};

    //- loop over the collection of documents (i.e one per country)
    documentsCollection.forEach((countryCollection) => {
      //- iterate the descriptions array for each country (if they exist). For each description, create a document entity
      if (countryCollection.descriptions?.length) {
        countryCollection.descriptions.forEach((description) => {
          emptyRow = rootGetters["schemas/emptyRow"]("documents", uid());
          emptyRow.attributes.transaction = countryCollection.transaction;
          emptyRow.attributes.description = description;
          emptyRow.attributes.countryCode = countryCollection.country_code;
          emptyRow.attributes.notarialActivity = 1;
          docs.push(emptyRow);
        });
      }

      //- cater for the case where there are fewer provided descriptions than the number of expected documents
      number = Number(countryCollection.number);
      filteredDocs = docs.filter(
        (x) => x.attributes.countryCode === countryCollection.country_code
      );
      if (number > filteredDocs.length) {
        const rowsToAdd = number - filteredDocs.length;
        if (rowsToAdd > 0) {
          for (let index = 0; index < rowsToAdd; index++) {
            emptyRow = rootGetters["schemas/emptyRow"]("documents", uid());
            emptyRow.attributes.transaction = countryCollection.transaction;
            emptyRow.attributes.notarialActivity = 1;
            emptyRow.attributes.countryCode = countryCollection.country_code;
            docs.push(emptyRow);
          }
        }
      }
    });
    return docs;
  };

/**
 * Gets the amount, multiplies by quantity, adds VAT if due.
 * @param {object} dataObject
 * @param {string} key
 */
export const getFeeWithVat = (dataObject, key) => {
  let feeTotal = 0;
  const fieldPrefix = key;
  const amount = Number(dataObject[`${fieldPrefix}Amount`])
  const quantity = Number(dataObject[`${fieldPrefix}Quantity`])
  const vat = (Boolean(dataObject[`${fieldPrefix}VatRequired`])
    ? (Number(dataObject[`${fieldPrefix}Amount`]) / 100) *
    Number(dataObject[`${fieldPrefix}VatPercentage`])
    : 0);

  feeTotal +=
    amount *
    //- multiplied by quantity
    quantity +
    //- plus VAT
    vat;
  // console.log('getFeeWithVat feeTotal', feeTotal);
  return feeTotal;
};

export const getFilteredAncillaryOptions =
  (state, getters, rootState, rootGetters) => (step) => {
    let options = [];
    if (
      !rootGetters["shared/legalisationRequired"](step.selected) ||
      !getters.showFcoAgentFees(step.country_code)
    ) {
      options = rootGetters["shared/getAncillaryOptions"].filter(
        (option) => option.value !== "legalisation_agent"
      );
    } else {
      options = rootGetters["shared/getAncillaryOptions"];
    }
    return options;
  };

export const getIntegerField = (state, getters) => (val) => {
  if (val === null) return null;
  return parseInt(getters.getField(val)) || null;
};

export const legalisationSteps = (state, getters) => {
  if (getters.formDataPath === undefined) return undefined;
  return getters.getField(
    `${getters.formDataPath}.attributes.legalisationSteps`
  );
};

export const stepperStep = (state) => {
  return state.stepper.step;
};

export const stepper = (state) => {
  return state.stepper;
};

export const getPaymentPortionType = (state, getters) => {
  const paymentPortion = _.get(
    state,
    `${getters.formDataPath}.attributes.paymentPortion`
  );
  return paymentPortion;
};

export const getRelatedAssociationRecordsWithDefault =
  (state, getters, rootState, rootGetters) => (entity, associationName) => {
    //- check for related value in reststate store
    const reststateRelatedVal = rootGetters[
      `${lcfirst(associationName)}/related`
    ]({
      parent: { id: entity.id, type: entity.type },
    });
    if (!empty(reststateRelatedVal)) return reststateRelatedVal;

    //- check for current related unpersisted value in currentForm, i.e. default value from notarial or enquiry
    const currentFormVal = getters.currentFormData.related[associationName];
    if (!empty(currentFormVal)) return currentFormVal;

    //- final fallback, get correct data-type from schema and return empty node
    const association =
      rootGetters["schema/getAssociationByName"](associationName);
    const targetTable = getAssociationTargetTable(association);
    const emptyRow = rootGetters["schema/emptyRow"](targetTable);
  };

export const getRequiredCountry = (state, getters) => (code) => {
  const country =
    _.get(state, `${getters.formDataPath}.attributes.requiredCountries`).filter(
      (x) => x.code === code
    )[0] || "";
  return country;
};

export const globalNotaryFeesTotal = (state, getters) => {
  let feeTotal = 0;
  const paymentType = _.get(
    state,
    `${getters.formDataPath}.attributes.paymentType`,
    undefined
  );
  // console.log("getNotaryFeesTotal paymentType", paymentType);
  if (typeof paymentType === "undefined") return feeTotal;
  const paymentNotarialFeeSplit = _.get(
    state,
    `${getters.formDataPath}.attributes.paymentNotarialFeeSplit`
  );
  // console.log("getNotaryFeesTotal paymentNotarialFeeSplit", paymentNotarialFeeSplit);
  if (paymentNotarialFeeSplit !== 1) {
    //- per appointment, just return notaryFeeAmount
    const attributes = _.get(state, `${getters.formDataPath}.attributes`);
    // console.log("getNotaryFeesTotal attributes", attributes);
    feeTotal += getFeeWithVat(attributes, "notaryFee");
  } else {
    //- per country is handled by feeTotalsBySection
  }
  return feeTotal;
};


export const feeTotalsBySection = (state, getters, rootState, rootGetters) => {
  const feeSections = rootGetters['fees/feeSections'];
  let total = 0
  // console.log(Object.keys(feeSections));
  for (const section of Object.keys(feeSections)) {
    const pathToCountryCollection = `${getters.formDataPath}.attributes.fees`
    // console.log("feeTotalsBySection pathToCountryCollection", pathToCountryCollection);
    const countryCollection = getters.getCollection(pathToCountryCollection);
    if (countryCollection.length < 1) continue;
    for (const countryFees of countryCollection) {
      const countrySectionRows = getters.getCountryCollectionValue(
        pathToCountryCollection,
        countryFees.country_code,
        section
      );

      if (!countrySectionRows || countrySectionRows.length < 0) continue;
      // console.log("feeTotalsBySection section", section);
      // console.log("feeTotalsBySection countrySectionRows", countrySectionRows);
      for (const row of countrySectionRows) {
        // console.log("feeTotalsBySection row", row);
        const amount = Number(row.amount);
        // console.log("feeTotalsBySection amount", amount);
        const quantity = Number(row.quantity);
        // console.log("feeTotalsBySection quantity", quantity);
        const vat = (Boolean(row.vatRequired)
          ? (amount / 100) * Number(row.vatPercentage)
          : 0);
        // console.log("feeTotalsBySection vat", vat);
        total += amount * quantity + vat;

        // console.log("feeTotalsBySection total", total);
      }
    }
  }
  // console.log("feeTotalsBySection total", total);
  return total;
}

export const totalFees = (state, getters) => {
  let feeTotal =
    getters.globalNotaryFeesTotal
    + getters.feeTotalsBySection;
  return feeTotal;
};

export const hasDocuments = (state, getters) => {
  return !!getters.documents?.length;
};

export const hasDefaultFees = (state, getters) => {
  if(!getters.fees.length) {
    return false;
  }
  return Boolean(getters.fees.some((fee) => fee.defaultfees));
}

/**
 * used by AppointmentDetails summary validation rules
 * @returns
 */
export const hasMissingColumnsForContactType =
  (state, getters) => (contactType) =>
    ((missingColumns) =>
      !missingColumns
        ? true
        : missingColumns.map((v) => ucfirst(v)).join(", "))(
          getters.whichIncompleteAppointmentDetailsContacts[contactType]
        );

export const hasMissingColumnsForOganisation = (state, getters) =>
  ((missingColumns) =>
    !missingColumns ? true : missingColumns.map((v) => ucfirst(v)).join(", "))(
      getters.whichIncompleteAppointmentDetailsOrganisations
    );

export const id = (state, getters) => {
  if (getters.formDataPath === undefined) return undefined;

  return getters.getField(`${getters.formDataPath}.id`);
};

/**
 * @returns boolean indicating whether the browser state differs from the saved state (if present or empty model state if not) for this model
 */
export const isDirtyJsonModel =
  (state, getters, rootState, rootGetters, from) => (model, isDifferent) => {
    log(from, "isDirtyJsonModel model", model);
    if (typeof getters.isDirtyAttributes == "undefined") {
      throw new Error("missing getters.isDirtyAttributes");
    }
    log(from, "isDirtyJsonModel isDirtyAttributes", getters.isDirtyAttributes);

    if (!getters.isDirtyAttributes) return false; //- can't be dirty if the schema isn't even dirty

    // get the current saved state for that model
    let submissionState = getters[model];
    if (Array.isArray(submissionState))
      submissionState = submissionState.map((x) => strip(x));
    if (_.isEmpty(submissionState)) {
      return false; //- can't be dirty if nothing in store is ready for submission, i.e. no model provisioned
    }
    let isDirty;

    function compareModelToState() {
      log(from, "isDirtyJsonModel compareModelToState", true);
      log(
        from,
        "isDirtyJsonModel isDifferent function provided? ",
        typeof isDifferent !== "undefined"
      );

      const emptyModel =
        rootGetters["countries/emptyJsonModel"](model).emptyRow();
      // pass both through the provided isEqual function
      const comparator = isDifferent ? isDifferent : defaultIsDifferent;
      log(from, "isDirtyJsonModel comparator", comparator)
      isDirty = comparator({
        baseState: emptyModel,
        compareState: submissionState,
        rootGetters,
        from,
      });
      log(from, "isDirtyJsonModel isDirty by comaparator?", isDirty)
      return isDirty;
    }

    let savedState = getters.entity?.attributes?.[model];
    log(from, "savedState", savedState);
    function compareSavedToState() {
      log(from, "compareSavedToState", true);

      if (Array.isArray(savedState)) {
        savedState = savedState.map((x) => strip(x));
      }
      // get the relevant attribute by path (using getField utility)
      isDirty = !_.isEqual(savedState, submissionState, from);
      return isDirty;
    }

    // if new record, compare with empty model
    if (!getters.isUpdate) {
      isDirty = compareModelToState();
    } else {
      if (_.isEmpty(savedState)) {
        isDirty = compareModelToState();
      } else {
        isDirty = compareSavedToState();
      }
    }

    log(from, "final isDirty", isDirty);
    // return boolean
    return isDirty;
  };

export function isDifferentFees({ baseState, compareState, rootGetters, from } = {}) {
  log(from, "isDifferentFees", true)
  log(from, "isDifferentFees baseState", baseState)
  log(from, "isDifferentFees compareState", compareState)
  const omissions = ["country_code"];
  const emptyRowWithOmmissions = _.omit(strip(baseState), "country_code");

  let isEqual = compareState.reduce((prev, row) => {
    row = _.omit(strip(row), "country_code");
    if (!prev) return prev;
    return _.isEqual(emptyRowWithOmmissions, row);
  }, true);

  log(from, "isDifferentFees first isEqual", isEqual); //- i.e. before we specifically check defaultfees
  if (isEqual) return false; // @why: if there's no diff then it's definitely not dirty. If there is, then it might be the defaults, which check next

  isEqual = compareState.reduce((prev, countryRow, index) => {
    log(from, "isDifferentFees compareState reduce countryRow", countryRow);
    log(from, "isDifferentFees compareState reduce rootGetters", rootGetters);
    if (!prev) return prev;
    if (!countryRow.country_code) {
      throw new Error("isDirtyFees state row is missing country_code");
    }

    //- @why: construct the dynamically provisioned defaultfees as the base state to compare the current state to, for this countryrow
    let defaultfees = rootGetters["fees/primaryServiceDefaultFees"](
      countryRow.country_code
    );

    //- if defaultfees were provisioned, then compare them to state
    if (defaultfees.length) {

      //- there's a data transform that runs after provisioning from the database to create the final default, so run that.
      defaultfees = defaultfees.map((defaultfee) => {
        log(from, "isDifferentFees defaultfee", defaultfee)
        const optionsForDynamicFeeRow = {
          feeIdx: index,
          country_code: countryRow.country_code,
          nodeName: "defaultfees",
          entity: defaultfee,
          tier: rootGetters["fees/serviceTier"](
            defaultfee.relationships.primaryservice.data.id,
            'isDifferentFees'
          ),
        };
        return rootGetters["fees/dynamicFeeRowData"](optionsForDynamicFeeRow);
      });

      log(from, "isDifferentFees mapped defaultfees", defaultfees)

      /**
       * COMPARE DEFAULTFEES SECTION
       */
      const a = defaultfees;
      const b = countryRow.defaultfees.map((x) => {
        //- @why: idx gets added after the fact, so we can't compare it
        const row = strip(_.omit(x, ["idx"]));
        /**
         * @why: for fees that are per document,
         * the default fee will have a quantity of null,
         * but the quantity for the state is set dynamically,
         * so we can set qunatity back to null for the isEqual check
         */
        row.quantity = null;
        return row;
      });
      log(from, "isDifferentFees a", a)
      log(from, "isDifferentFees b", b)
      let isEqual = _.isEqual(a, b);
      log(from, "second isEqual", isEqual);
      if (!isEqual) {
        return false;
      } //- @why: if it's not equal then isDirty is true and no need to proceed.

      /**
       * COMPARE OTHER SECTIONS
       */
      const otherRowSections = _.omit(countryRow, ["defaultfees", "country_code"]);
      isEqual = Object.values(otherRowSections).every(
        (section) => section.length === 0
      );
      return isEqual;
    }
  }, true);

  log(from, "third isEqual", isEqual);
  return !isEqual;
}

/**
 * i.e.
 * @returns boolean
 */
export const isDirtyFees = (state, getters, rootState, rootGetters, from) => {
  // window.log.d(from, "isDirtyFees starting", true);

  if (getters.isDirtyAttributes) {
    const path = `${getters.formDataPath}.attributes.invoiceeRequired`;
    const invoiceeRequired = getters.getField(path);
    const savedState = getters.entity?.attributes;
    // window.log.d('isDirtyFees savedState', savedState);

    if (
      savedState?.invoiceeRequired !== undefined &&
      invoiceeRequired != savedState?.invoiceeRequired
    ) {
      // window.log.d('isDirtyFees true because', savedState, InvoiceRecipient)
      return true;
    }
  }
  if (getters.isDirtyAssociations) {
    const dirtyAssociations = getters.submissionsDirtyAssociations;
    for (let association of dirtyAssociations) {
      if (association.name === "Invoicees") {
        // window.log.d('isDirtyFees true because', dirtyAssociations, InvoiceRecipient)
        return true;
      }
    }
  }

  //- @why: some of the fee attributes like payment details are standard enquiry attributes
  if(getters.isDirtyAttributes) {
    // if the only dirtyattribute is `fees` then ignore
    if(getters.dirtyAttributes.length != 1 || getters.dirtyAttributes[0] !== 'fees') {
      // window.log.d('isDirtyFees true because isDirtyAttributes', getters.dirtyAttributes)
      return true
    }
  }
  const isDirtyBecauseJsonNotMatchEmptyModel = getters.isDirtyJsonModel("fees", isDifferentFees);
  // window.log.d('isDirtyFees final result: isDirtyBecauseJsonNotMatchEmptyModel', isDirtyBecauseJsonNotMatchEmptyModel)
  return isDirtyBecauseJsonNotMatchEmptyModel;
};

export const isDirtyLegalisationSteps = (
  state,
  getters,
  rootState,
  rootGetters,
  from
) => {
  function isDifferent({ baseState, compareState, from } = {}) {
    const reducerFn = (isDifferent, row) => {
      if (isDifferent) return isDifferent;
      // @why - selected has dynamically added defaults, so ignore its values unless default is overridden
      const omissions = row.selected.includes(-1)
        ? ["country_code"]
        : ["country_code", "selected"];

      log(from, "row", row);
      log(from, "omissions", omissions);
      const rowWithoutCountryCode = _.omit(row, ...omissions);
      const emptyRowWithoutCountryCode = _.omit(baseState, ...omissions);
      const isEqual = _.isEqual(
        rowWithoutCountryCode,
        emptyRowWithoutCountryCode
      );
      if (from) {
        log(from, "row", row);
        log(from, "rowWithoutCountryCode", rowWithoutCountryCode);
        log(from, "emptyRowWithoutCountryCode", emptyRowWithoutCountryCode);
        log(from, "isEqual", isEqual);
      }
      return !isEqual;
    };
    const isDifferent = compareState.reduce(reducerFn, false);
    return isDifferent;
  }
  log(from, "here");

  return getters.isDirtyJsonModel("legalisationSteps", isDifferent);
};

/**
 * @returns boolean indicating whether the browser state differs from the saved state for this model
 */
export const isDirtyExpectedDocuments = (
  state,
  getters,
  rootState,
  rootGetters,
  from
) => {
  // window.log.d('isDirtyExpectedDocuments starting', true);
  // window.log.d(getters.isDirtyAttributes)

  //- if globalTBC does not match saved state, then is dirty
  if (getters.isDirtyAttributes) {
    const path = `${getters.formDataPath}.attributes.documentsTbc`;
    const documentsTbc = getters.getField(path);
    log(from, "isDirtyExpectedDocuments path", path);
    // window.log.d("documentsTbc", documentsTbc);
    log(from, "getters.entity", getters.entity);
    const savedState = getters.entity?.attributes;
    if (
      (savedState?.documentsTbc === undefined || savedState?.documentsTbc === null) &&
      documentsTbc != null
    ) {
      return true;
    }
    
  }

  const result = getters.isDirtyJsonModel("expectedDocuments");
  log(from, "result", result);
  return result;
};

function defaultIsDifferent({
  baseState,
  compareState,
  omit = [],
  reducer = false,
  dynamicBaseRowModifier = (x) => x,
  from,
} = {}) {
  // if global documentsTbc is true, then return false
  if (from) {
    log(from, "defaultIsDifferent", from);
  }
  const reducerFn = reducer
    ? reducer
    : (isDifferent, row) => {
      if (isDifferent) return isDifferent;
      const omissions = ["country_code", ...omit];
      const rowWithoutCountryCode = _.omit(row, ...omissions);
      const emptyRowWithoutCountryCode = _.omit(
        dynamicBaseRowModifier(baseState),
        ...omissions
      );
      const isEqual = _.isEqual(
        strip(rowWithoutCountryCode),
        strip(emptyRowWithoutCountryCode)
      );
      if (from) {
        log(from, "row", row);
        log(from, "rowWithoutCountryCode", rowWithoutCountryCode);
        log(from, "emptyRowWithoutCountryCode", emptyRowWithoutCountryCode);
        log(from, "isEqual", isEqual);
      }
      return !isEqual;
    };
  const isDifferent = compareState.reduce(reducerFn, false);
  return isDifferent;
}
/**
 * @param {string} model i.e. which JSON model, the name
 * @param {function} validator e.g. expectedDocuments may be dirty if a different data point outside of the JSON model is flagged. The validator function allows such things to be checked. Return true if submitted false if not.
 * @param {function} dynamicBaseRowModifier e.g. legalisationSteps stepper key's value has dynamic defaults, which need to be mapped to the emptyModel to set the baseline for checking difference against. Fees JSON model would need to find all the defaultfees and set those in the baseState for comparison
 * @returns boolean indicating whether the saved state differs from the provisioned state of the model
 */
export const isSubmittedJsonModel =
  (state, getters, rootState, rootGetters, from) =>
    ({
      model,
      validator = () => false,
      dynamicBaseRowModifier = (x) => x,
      isDifferent = defaultIsDifferent,
    } = {}) => {
      log(from, "getters.isUpdate", getters.isUpdate);
      log(from, "rootGetters", rootGetters);
      // if not update then can't be submitted
      if (!getters.isUpdate) return false;

      // run the validator method first as it may contain overrides to make this true
      let isSubmitted = validator();
      if (isSubmitted === true) return true; //- @why: only if explicitly true, otherwise, keep testing

      // fetch the statically provisioned model
      const staticModel =
        rootGetters["countries/emptyJsonModel"](model).emptyRow();
      log(from, "staticModel", staticModel);
      // log(from, 'staticModel'. staticModel)

      // find and make any dynamically provisioned changes to that model using the provided dynamics function
      log(from, "getters.entity", getters.entity);

      // get the current saved state for that model
      if(getters.entity?.attributes?.[model] === undefined) {
        return false;
      }
      const savedState = getters.entity.attributes[model];
      log(from, "savedState", savedState);
      const notSaved = _.isEmpty(strip(savedState));
      log(from, "notSaved", notSaved);
      if (notSaved) return false;

      isSubmitted = isDifferent({
        baseState: staticModel,
        compareState: savedState,
        dynamicBaseRowModifier,
        rootGetters,
        from,
      });
      log(from, "isSubmitted", isSubmitted);

      // return boolean

      return isSubmitted;
    };

/**
 * @returns boolean indicating whether the saved state differs from the provisioned state of the model
 */
export const isStepSubmittedFees = (
  state,
  getters,
  rootState,
  rootGetters,
  from
) => {
  return getters.isSubmittedJsonModel({
    model: "fees",
    isDifferent: isDifferentFees
  });
};

/**
 * @returns boolean indicating whether the saved state can be considered validated and complete for the business rules
 */
export const isCompleteJsonModel =
  (state, getters, rootState, rootGetters, from) => (model, validator) => {
    log(from, "model", model);
    log(from, "getters", getters);
    log(from, "getters.isUpdate", getters.isUpdate);
    // if not update then can't be submitted
    if (!getters.isUpdate) return false;

    // get the current saved state for that model;
    const savedState = getters.entity?.attributes?.[model];
    log(from, "savedState", savedState);
    const notSaved = _.isEmpty(strip(savedState));
    log(from, "notSaved", notSaved);
    if (notSaved) {
      return false;
    }
    log(from, "continue to test isComplete", true);

    // run the final model vs the saved state through the validator method
    let isComplete = savedState.reduce((prev, row) => {
      log(from, "in reduce", prev);
      if (!prev) return prev;
      return validator(row);
    }, true);

    log(from, "isComplete", isComplete);
    // return boolean
    return isComplete;
  };

/**
 * @returns (boolean) indicating if the stored state meets minimum information requirements
 */
export const isCompleteLegalisationSteps = (
  state,
  getters,
  rootState,
  rootGetters,
  from
) => {
  // window.log.d(1, "isCompleteLegalisationSteps", "starting");
  // window.log.d(2, "getters", getters);
  function validator(row) {
    // window.log.d(3, "validator", row);
    if (row.timescales === null) return false;
    // window.log.d(4, "validator timescales OK");
    if (row.document_fwd_instructions === 5) return false;
    // window.log.d(5, "validator document_fwd_instructions OK");
    if (
      row.document_fwd_instructions > 1 &&
      row.document_fwd_instructions < 4 &&
      (row.document_fwd_courier_info_name === "" ||
        row.document_fwd_courier_info_phone === "" ||
        row.document_fwd_courier_info_address === "" ||
        row.document_fwd_delivery_type === "")
      )
      return false;
      // window.log.d(6, "validator delivery details OK");
      if (row.document_fwd_courier_info_defer === true) return false;
      // window.log.d(7, "validator document_fwd_courier_info_defer OK");
      // window.log.d(8, "row.primaryServiceId", row.primaryServiceId);
      if (!row.selected.length) return false;
      // window.log.d(9, "row.selected", row.selected);
      // window.log.d(10, "validator selected OK");
      // window.log.d(11, "validator selected includes override", !row.selected.some(value => value === -1 || value === -2));
      // window.log.d(12, "row.primaryServiceId === ''", row.primaryServiceId === "");

      //- if no override, then primaryServiceId must be set
      if (!row.selected.some(value => value === -1 || value === -2) && row.primaryServiceId === "") return false;
      // window.log.d(13, "validator primaryServiceId OK");
    return true;
  }
  const isComplete = getters.isCompleteJsonModel(
    "legalisationSteps",
    validator
  );
  // window.log.d(from, "parent isComplete", isComplete);
  return isComplete;
};

export const isCompleteExpectedDocuments = (
  state,
  getters,
  rootState,
  rootGetters,
  from
) => {
  // window.log.d("isCompleteExpectedDocuments", "starting");
  const documentsTbc = getters.getField(
    `${getters.formDataPath}.attributes.documentsTbc`
  );

  // window.log.d("documentsTbc", documentsTbc);
  if (documentsTbc === true) return false;

  function validator(row) {
    // window.log.d("validator starting", true);
    // window.log.d("row", row);
    if (row.tbc == true) return false;
    // window.log.d("row.tbc");
    if (row.tbc) return false;
    // window.log.d("3");
    if (typeof row.number !== "string") return false;
    // window.log.d("4");
    if (typeof row.transaction !== "string") return false;
    // window.log.d("5");
    if (!Array.isArray(row.descriptions)) return false;
    // window.log.d("6");
    if (!row.transaction) return false;
    // window.log.d("7");
    if (_.isEmpty(row.descriptions)) return false;
    // window.log.d("8");
    if (empty(row.number)) return false;
    // window.log.d("9");
    if (Number(row.number) !== row.descriptions.length) return false;
    // window.log.d("10");
    return true;
  }

  log(from, "getters", getters);
  const isComplete = getters.isCompleteJsonModel(
    "expectedDocuments",
    validator
  );
  log(from, "parent isComplete", isComplete);
  return isComplete;
};

/**
 * @returns boolean indicating whether the saved state can be considered validated and complete for the business rules
 */
// export const isCompleteJsonModel =
//   (state, getters, rootState, rootGetters, from) => (model, validator) => {
//     // fetch the reststate object from the store (which represents the saved state) by id
//     const savedState = getters.entity.attributes[model];
//     log(from, "savedState", savedState);

//     // find and make any dynamically provisioned changes to that model using the provided dynamics function
//     return savedState.reduce((prev, row) => {
//       if (!prev) return prev;
//       return validator(row);
//     }, true);
//   };

export const isStepSubmittedLegalisationSteps = (
  state,
  getters,
  rootState,
  rootGetters,
  from
) => {
  return getters.isSubmittedJsonModel({
    model: "legalisationSteps",
    dynamicBaseRowModifier: (row) => {
      const rules = rootGetters["countries/rulesForCountryCode"](
        row.country_code
      );
      row.selected = rules
        .filter((option) => option.value > -1)
        .map((option) => option.value);
    },
  });
};
/**
 * @returns boolean indicating whether the saved state differs from the provisioned state of the model
 */
export const isStepSubmittedExpectedDocuments = (
  state,
  getters,
  rootState,
  rootGetters,
  from
) => {
  function validator() {
    const path = `${getters.formDataPath}.attributes.documentsTbc`;
    const documentsTbc = getters.getField(path);
    if (documentsTbc === true) return true;
  }
  return getters.isSubmittedJsonModel({
    model: "expectedDocuments",
    validator,
  });
};

//- @deprecated: there's no way to know if fees are complete. The closest we can get is checking that the form is valid, which is done in the summary step.
// export const isCompleteFees = (
//   state,
//   getters,
//   rootState,
//   rootGetters,
//   from
// ) => {
//   const fees = getters.fees;
//   if (_.isEmpty(fees)) return false;
//   for (let countryfee of fees) {
//     window.log.d('countryfee', countryfee);
    
//   }
//   return true;
// };


export const isCountrySelected = (state, getters) => {
  return Boolean(getters.requiredCountries.length);
};

export const isDeliverySelected = (state, getters) => (country_code) => {
  return Boolean(
    getters.getCountryCollectionValue(
      `${getters.formDataPath}.attributes.legalisationSteps`,
      country_code,
      "document_fwd_delivery_type"
    )
  );
};

export const isDirty = (state, getters) => {
  return getters.isDirtyAttributes || getters.isDirtyAssociations;
};

/**
 * @why only useful for the Primaryassociation as it uses scheam/isDirtyAssociations, which needs to know which schema to use
 */
export const isDirtyAssociations = (state, getters, rootState, rootGetters) => {
  if (!getters.isLoaded) return false;
  if (_.isEmpty(getters.schema)) return false;
  if (!empty(getters.isUpdate) && empty(getters.entity)) return false;
  if (_.isEmpty(getters.currentFormData)) return false;
  const options = {
    schema: getters.schema,
    entity: getters.entity || getters.id,
    limit: getters.defaultAssociations,
    pathOrData: getters.currentFormData.related,
    from: "submissions getter",
    debug: false, //= e.g. "Notaries" select an association to debug
  };
  // console.log('isDirtyAssociations options', options );

  return rootGetters["schemas/isDirtyAssociations"](options);
};

/**
 * @why useful for knowing whether a form contains submitted, unsaved data
 * @returns Boolean
 */
export const isDirtyAttributes = (state, getters, rootState, rootGetters) => {
  if (!getters.isLoaded) return false;
  if (_.isEmpty(getters.schema)) return false;
  if (!empty(getters.isUpdate) && empty(getters.entity)) return false;
  if (_.isEmpty(getters.currentFormData)) return false;
  const options = {
    schema: getters.schema,
    entity: getters.entity
      ? getters.entity
      : rootGetters["schemas/entityDammit"](getters.schema, null), //@why: schemas/isDirtyAttributes can't evaluate without an entity
    attributes: getters.currentFormData?.attributes,
    from: "submissions getter",
  };
  return rootGetters["schemas/isDirtyAttributes"](options);
};

/**
 * Experimental
 */
export const isDirtyNotarialCompliance = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const notarialCompliance = getters.currentFormData.related?.Compliance;
  if (!notarialCompliance) return false;
};

export const isDraft = (state, getters) => {
  return _.get(state, `${getters.formDataPath}.attributes.draft`, undefined);
};

export const isDirtyFeeMetaData = (state, getters) => {
  const dirtyAttributes = getters.dirtyAttributes;
  if(!dirtyAttributes) return false;
  window.log.d("isDirtyFeeMetaData dirtyAttributes", dirtyAttributes);
  const fields = [
    "invoiceeRequired",
    "notaryFeeAmount",
    "paymentType",
    "paymentPortion",
    "paymentDeposit",
    "paymentPoNumber",
    "paymentNotarialFeeSplit"
  ]
  return fields.some((field) => dirtyAttributes.includes(field));
}

export const isDirtyOtherJsonModel =
  (state, getters, rootState, rootGetters) => (modelName) => {
    const models = Object.keys(rootGetters["countries/emptyJsonModels"]);
    // remove modelName from models array
    const otherModelNames = models.filter((model) => model !== modelName);
    // console.log('modelName', modelName );
    // console.log('models', models );
    // console.log('otherModelNames', otherModelNames );
    // console.log('_.isEmpty(otherModelNames)',_.isEmpty(otherModelNames) );
    if (_.isEmpty(otherModelNames)) return false;
    for (let modelName of otherModelNames) {
      const capitalisedModelName = _.upperFirst(modelName);
      if (getters[`isDirty${capitalisedModelName}`]) return true;
    }
    return false;
  };

export const isForwardingAddressForStepUnnecessaryOrComplete =
  (state, getters, rootState, rootGetters) => (step) => {
    if (
      !rootGetters["shared/showLegalisationAgentFwdOptions"](
        step.document_fwd_instructions
      )
    )
      return true;
    if (step.document_fwd_courier_info_defer) return true;
    return (
      step.document_fwd_address?.length &&
      step.document_fwd_courier_info_name?.length &&
      step.document_fwd_courier_info_phone?.length
    );
  };

export const isLegalisationStepOverridden =
  (state, getters) => (countryCode) => {
    const step = getters.getCountryLegalisationStep(countryCode);
    if (_.isEmpty(step)) return false;
    return step.selected.length > 0 && step.selected[0] < 0;
  };

export const isListLoading = (state) => {
  return state.isListLoading;
};

export const isLoaded = (state, getters) => {
  if (getters.formSettingsPath === undefined) return undefined;
  return getters.getField(`${getters.formSettingsPath}.isLoaded`);
};

export const isPrimeEntity = (state, getters) => (entity) => {
  return getters.entity?.type === entity.type; // @why optional chaining; to prevent errors for INSERT context where this.entity will be undefined
};

export const isSubmissionsDirtyAssociations = (state, getters) => {
  return !!getters.submissionsDirtyAssociations.length;
};

export const isSubmitted = (state, getters) => {
  if (getters.formDataPath === undefined) return undefined;
  return getters.getField(`${getters.formSettingsPath}.isSubmitted`);
};

export const isUpdate = (state, getters) => {
  if (getters.formSettingsPath === undefined) return undefined;
  return getters.getField(`${getters.formSettingsPath}.isUpdate`);
};

export const legalisationRequired = (state, getters) => {
  const required = Boolean(
    getters.requiredCountries?.filter((country) => {
      return country.legal_code !== 0;
    }).length
  );
  return required;
};

export const legalisationEmbassyNotRequired = (state, getters) => (country_code) => {
  const step = getters.getCountryLegalisationStep(country_code);
  return step.legalisation_agent_FCDO === 3;
};

export const legalisationChamberNotRequired = (state, getters) => (country_code) => {
  const step = getters.getCountryLegalisationStep(country_code);
  return step.legalisation_agent_embassy === 3;
};

export const notarialOrganisations = (state, getters) => {
  return state.forms.notarial.data.related.Organisations;
};

export const organisationEntityTypes = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const types = rootGetters["entityTypes/all"];
  if (empty(types)) return [];
  return types.filter((x) => x.id != 1);
};

export const path = (state) => {
  return _.get(state, "path", "");
};

export const postalServiceSelectedDoesNotRequireAgent =
  (state, getters, rootState, rootGetters) => (country_code) => {
    const step = getters.getCountryLegalisationStep(country_code);
    if (rootGetters["shared/isFCO"](step.selected)) {
      if (
        step.primaryServiceId === types.FEE_FCO.standard ||
        step.primaryServiceId === types.FEE_FCO.NA
      )
        return true;
    }
    return false;
  };

export const defaultAssociations = (state, getters) => {
  if (getters.formSettingsPath === undefined) return undefined;
  return getters.getField(`${getters.formSettingsPath}.defaultAssociations`);
};

export const queryKey = (state) => {
  return state.forms[state.path].settings.queryKey;
};

export const redBadges = (state, getters) => {
  const signatories = getters.signatories;
  const compliance = getters.complianceRecords;
  const documents = getters.documents;
  if (empty(signatories) || empty(compliance) || empty(documents)) return true;
  let badges;
  badges = documents.map((d) => getters.documentHasFile(d));
  if (badges.includes(false)) return true;
  badges = compliance.map(
    (c) =>
      getters.complianceValidSignature(c) &&
      getters.complianceRecordHasAuthority(c)
  );
  if (badges.includes(false)) return true;
  badges = signatories.map(
    (signatory) =>
      getters.signatoryValidProofOfId(signatory) &&
      getters.signatoryValidProofOfAddress(signatory) &&
      dataProtectionValidSignature(signatory)
  );
  if (badges.includes(false)) return true;
};

export const related = (state, getters) => {
  if (getters.formDataPath === undefined) return undefined;
  return getters.getField(`${getters.formDataPath}.related`);
};

export const relatedComplianceRecordsByPerson =
  (state, getters, rootState, rootGetters) => (person) => {
    const relatedComplianceRecordsByPerson = rootGetters["compliance/related"]({
      parent: {
        id: person.id,
        type: person.type,
      },
    });
    return relatedComplianceRecordsByPerson;
  };

export const representatives = (state, getters, rootState, rootGetters) => {
  const documents = getters.documents;
  if (!documents?.length) return [];
  let representatives = new Set([]);
  _.each(documents, (document) => {
    let Representatives = document.related?.Representatives;
    //- cater for variable types - object or array (i.e. one or many)
    if (Representatives != undefined) {
      if (Representatives.length) {
        //- array
        Representatives.map((representative) => {
          representatives.add(representative);
        });
      } else {
        //- object
        if (Representatives.id !== undefined) {
          representatives.add(Representatives);
        }
      }
    }
  });
  const representativeEntities = rootGetters["people/idIn"]({
    ids: [...representatives].map((x) => x.id),
  });
  return representativeEntities;
};

export const requiredCountries = (state, getters) => {
  if (getters.formDataPath === undefined) return undefined;
  return getters.getField(
    `${getters.formDataPath}.attributes.requiredCountries`
  );
};

export const countRequiredCountries = (state, getters) => {
  const reqCountries = getters.requiredCountries
  if(Array.isArray(strip(reqCountries))) {
    return reqCountries.length
  }
  return Boolean(reqCountries.country_code);
}

export const role = (state, getters) => {
  const entity = getters.entity;
  if(!empty(entity.id)) {
    return entity.relationships.role.data;
  }
};

/** used for roleId */
export const roleId = (state, getters) => {
  const role = getters.role;
  if(!empty(role.id)) {
    return Number(role.id);
  }
};

export const roleOption = (state, getters) => {
  return "roleOption";
};

export const schema = (state, getters) => {
  return _.get(state, `${getters.formSettingsPath}.schema`, "");
};

export const selectedAncillaryServicesForCountry =
  (state, getters) => (country_code) => {
    return getters.getCountryCollectionValue(
      `${getters.formDataPath}.attributes.legalisationSteps`,
      country_code,
      "ancillary_services"
    );
  };

export const showDelivery = (state, getters) => (country_code) => {
  let step = getters.getCountryLegalisationStep(country_code);
  if (_.isEmpty(step)) return false;
  const allow =
    [types.DOC_FWD.toAddress, types.DOC_FWD.toAgent].includes(
      step.document_fwd_instructions
    ) && step.document_fwd_delivery_type > 0;
  return allow;
};

export const showFcoAgentFees = (state, getters) => (country_code) => {
  return (
    getters.FCOstepSelected(country_code) &&
    !getters.postalServiceSelectedDoesNotRequireAgent(country_code)
  );
};

export const showForwardingAddress = (state) => (step) => {
  const show =
    step.document_fwd_delivery_type !== types.DOC_3RD.courier ||
    (step.document_fwd_delivery_type === types.DOC_3RD.courier &&
      !step.document_fwd_courier_info_defer);
  return show;
};

export const showSearch = (state) => {
  return state.showSearch;
};

export const showValidationBanner = (state) => {
  return state.showValidationBanner;
};

/**
 * @returns signatory records
 * @why : Return references to the people store records
 * Then if we wish to use these for form storage, clone
 * each locally within the responsible component [Ed. That didn't explain why: bad comment!]
 */
export const signatories = (state, getters, rootState, rootGetters) => {
  let signatories = [...getters.clients, ...getters.representatives];
  return signatories;
};

export const signatoryAuthorisations =
  (state, getters, rootState, rootGetters) => (person) => {
    return (
      rootGetters["authorisations/related"]({
        parent: { id: person.id, type: person.type },
      })?.filter((x) => x.attributes.isAuthorityProof != true) || []
    );
  };

export const signatoryValidProofOfAddress = (state, getters) => (signatory) => {
  const authorisations = getters.authorisations(signatory);
  if (empty(authorisations)) return false;
  return authorisations.reduce((previous, current, index) => {
    previous =
      current.attributes.isAddressProof &&
        !isExpired(
          current.attributes.expiryDate,
          current.attributes.isDraft ? undefined : current.attributes.modified
        )
        ? true
        : previous;
    return previous;
  }, false);
};

export const signatoryValidProofOfId = (state, getters) => (signatory) => {
  const authorisations = getters.authorisations(signatory);
  if (empty(authorisations)) return false;
  return authorisations.reduce((previous, current, index) => {
    previous =
      current.attributes.isIdentityProof &&
        !isExpired(
          current.attributes.expiryDate,
          current.attributes.isDraft ? undefined : current.attributes.modified
        )
        ? true
        : previous;
    return previous;
  }, false);
};

/**
 * @returns legalisationStep
 */
export const step = (state, getters) => (countryCode) => {
  return getters.getCountryLegalisationStep(countryCode);
};


export const submissionsDirtyAssociations = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  // console.log('submissionsDirtyAssociations is being checked' );

  if (!getters.isLoaded) return [];
  if (_.isEmpty(getters.schema)) return [];
  if (_.isEmpty(getters.currentFormData.related)) return []; // if no relationship data exists on the form, can't evaluate
  if (!empty(getters.isUpdate) && empty(getters.entity)) return []; // if this is definitely an UPDATE, then the entity must be present, otherwise can't evaluate.
  const options = {
    schema: getters.schema,
    entity: getters.entity || getters.id, // the latter caters for INSERT context where entity will be undefined
    pathOrData: getters.currentFormData.related,
    limit: getters.defaultAssociations,
    from: "submissionsDirtyAssociations",
    // debug: 'Clients'
  };
  if (process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS) {
    console.log("submissionsDirtyAssociations options", options);
    if(options.debug) {
      console.log('submissionsDirtyAssociations debug', options.debug);
    }
  }

  return rootGetters["schemas/dirtyAssociations"](options);
};

export const submissionsDirtyAssociationsWithFrom = (
  state,
  getters,
  rootState,
  rootGetters
) => (from) => {

  if (!getters.isLoaded) return [];
  if (_.isEmpty(getters.schema)) return [];
  if (_.isEmpty(getters.currentFormData.related)) return []; // if no relationship data exists on the form, can't evaluate
  if (!empty(getters.isUpdate) && empty(getters.entity)) return []; // if this is definitely an UPDATE, then the entity must be present, otherwise can't evaluate.
  const options = {
    schema: getters.schema,
    entity: getters.entity || getters.id, // the latter caters for INSERT context where entity will be undefined
    pathOrData: getters.currentFormData.related,
    limit: getters.defaultAssociations,
    from,
    // debug: 'Clients'
  };
  if (process.env.DEBUG_ALL_DIRTY_ASSOCIATIONS) {
    console.log("submissionsDirtyAssociations options", options);
    if(options.debug) {
      console.log('submissionsDirtyAssociations debug', options.debug);
    }
  }

  return rootGetters["schemas/dirtyAssociations"](options);
};

export const termsIsReady = (state, getters) => {
  return true;
};

export const relatedUserSettings = (state, getters) => {
  if (getters.formDataPath === undefined) return undefined;
  return getters.getField(
    `${getters.formDataPath}.related.Usersettings`
  );
};
export const relatedUserSettingsPeople = (state, getters) => {
  if (getters.formDataPath === undefined) return undefined;
  return getters.getField(
    `${getters.formDataPath}.related.UsersettingsPeople`
  );
};

// used internally above
export const whichIncompleteAppointmentDetailsContacts = (state, getters) => {
  const contacts = getters.getContacts;
  const requiredFieldsForCompletion = [
    "name",
    "surname",
    "email",
    "phone",
    "address1",
    "town",
    "country",
    "postcode",
  ];

  const missingColumnsByContactType = {};

  for (const contact of contacts) {
    if (
      contact.contact_type === "quotee" &&
      !getters.entity.attributes.quoteeRequired
    )
      continue;
    if (
      contact.contact_type === "invoicee" &&
      !getters.entity.attributes.invoiceeRequired
    )
      continue;

    const missingColumns = requiredFieldsForCompletion.filter(
      (curr) => !contact.attributes?.[curr]
    );
    if (missingColumns.length > 0) {
      if (!missingColumnsByContactType[contact.contact_type]) {
        missingColumnsByContactType[contact.contact_type] = [];
      }
      missingColumnsByContactType[contact.contact_type].push(...missingColumns);
    }
  }

  return missingColumnsByContactType;
};

export const whichIncompleteAppointmentDetailsOrganisations = (
  state,
  getters
) => {
  const organisations = toArray(
    _.get(state, `${getters.formDataPath}.related.Organisations`, [])
  );
  const requiredFieldsForCompletion = [
    "name",
    "email",
    "phone",
    "address1",
    "town",
    "country",
    "postcode",
  ];

  const missingColumnsByOrganisation = {};

  for (const org of organisations) {
    const missingColumns = requiredFieldsForCompletion.filter(
      (curr) => !org.attributes?.[curr]
    );
    if (missingColumns.length > 0) {
      missingColumnsByOrganisation.push(...missingColumns);
    }
  }

  return missingColumnsByOrganisation;
};

export const who = (state, getters) => {
  if (getters.formDataPath === undefined) return undefined;
  return getters.getField(`${getters.formDataPath}.attributes.who`);
};
